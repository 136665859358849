import React from 'react'
import IntegrationTemplate from '../../components/Integrations/IntegrationTemplate'

import joomlaLogo from '../../images/integration-logo-images/joomla.png'

const Joomla = () => {
    return (
        <>
            <IntegrationTemplate 

                metaTitle="Integrate Optinly with Joomla"
                metaDescription="Optinly is the smart pop-up builder for your Joomla store. With Optinly, you can seamlessly attract leads to grow your email list."

                otherProductLogo={joomlaLogo} //PLEASE IMPORT THE LOGO HERE LIKE THIS

                integrationApp="Joomla"
                homeTitle="Grow Your Email List with Joomla Popups"
                homeDescription="Integrate Optinly with your Joomla store. Ideal lead generation tool with visitor engaging popups to grow your email list. Increase Sales with smart popups. "

                docsTitle="Increase your Joomla store email list "
                docsDescription="Best Joomla popup app. A complete package to rapidly grow your email list, leads and subscribers. Select from pre-made popups or custom popup in your Joomla store. "

                featruesDocsSection1="Integeration and Customization "
                featruesDocsTitle1="Seamless Integeration and Advance Customization Options"
                featruesDocsDescription1="Customize popups based on user behavioural pattern. Design or development skill not required to customize popup. Joomla email popup through simple integeration. "

                featruesDocsSection2="Installation and Signup"
                featruesDocsTitle2 ="Joomla Popups To Improve Store Performance"
                featruesDocsDescription2="Hassle-free signup, download, and installation. Best Joomla popup form to deliver customer-friendly popup. Select the best email popup for Joomla from numerous existing templates."

                featruesDocsSection3="Achieve Marketing Goals "
                featruesDocsTitle3 ="One-stop Solution for Joomla Popup building"
                featruesDocsDescription3="Everything you need to create user engaging popups in one place. Advacned dashboard, simple integeration, regular update of pre-made templates and a clear user interface."

                featruesDocsSection4="Detailed Dashboard Analytics"
                featruesDocsTitle4 ="All-in-one Dashboard"
                featruesDocsDescription4="Know how your Joomla Popup is performing. Manage campaigns, make changes, and more with a powerful dashboard. Simple to navigate yet highly accurate analytics available."

                FAQQuestion1="Does Optinly offer Joomla email popup?  "
                FAQAnswer1="Optinly offers multiple redaymade Joomla email popups. Also, it provides options to easily customize the popup depending upon the user. Joomla email popups are popups used in Joomla websites for collecting quality customer email address. "

                FAQQuestion2="How many types of Joomla popup form is available in Optinly?"
                FAQAnswer2="Optinly offers 6+ types of unique popup forms for Joomla. Each one is engaging and grabs audience attentation without any delay. "

                FAQQuestion3="Is there a free pricing plan available in Optinly for Joomla stores? " 
                FAQAnswer3="Optinly have a free pricing plan that works on Joomla stores. Feel free to check out the entire pricing structure in Optinly pricing page.  "

                FAQQuestion4="Is Joomla exit popup available in Optinly? "
                FAQAnswer4="Yes, Optinly have customer engaging exit popup templates. You can personalize the template or made use of the pre-made one. "
            />
        </>
    )
}

export default Joomla
